<template>
  <span>
    <div
      v-if="!showDetail"
      class="text-center"
    >
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <b-card-header>
              <!-- title and subtitle -->

              <b-card-title class="mb-1">
                User
              </b-card-title>

              <!--/ title and subtitle -->
            </b-card-header>

            <b-card-body>
              <div
                style="padding:100px"
                class="text-center"
              ><b-spinner
                variant="primary"
                type="grow"
                label="Spinning"
              /><br>
                Loading data in progress..
              </div>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-row
      v-else
      class="user-details-wrapper"
    >
      <b-col
        cols="8"
        class="mobileScrn"
      >
        <user-identification
          :user="user"
          :avatar="avatar"
          @updateUser="updateUser($event)"
          @updateUserInvoices="updateUserInvoices($event)"
        />
      </b-col>
      <b-col
        cols="4"
        class="mobileScrn"
      >
        <user-package
          :user="user"
          :billing="billing"
          :discount-histories="discountHistory"
        />
        <b-col>
          <div style="display:flex; flex-direction:column; gap:10px; margin-bottom:20px;text-align: center;">
            <select
              class=" btn btn-sm btn-success"
              style="text-align: center; height: 30px; display: flex; align-items: center; justify-content: center; font-weight: bolder; gap:4px;"
              @change="setPackage($event)"
            >
              <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :class="option.value === '5003' ? 'optionFix2' : 'optionFix' && option.value === '6003' ? 'optionFix2' : 'optionFix'"
              >
                <p style="padding:4px;margin:10px;">{{ option.text }}</p>
              </option>
            </select>
            <button
              class="btn btn-primary btn-sm btn-block"
              style="height: 30px; display: flex; align-items: center; justify-content: center;"
              @click="changePackage()"
            >
              Change Package
            </button>
          </div>
        </b-col>
      </b-col>
      <b-col
        cols="12"
        class="text-center mb-1 action-btn-group mobileScrn"
      >
        <action
          v-if="action"
          :settings="settings"
          :user="user"
          :action="action"
          :billing="billing"
          :script="script"
          :show-user-setting="showUserSetting"
          @updateUser="updateUser($event)"
          @refreshUserInvoice="refreshInvoice"
        />
        <div
          v-else
          class="d-flex spinner-border text-primary justify-content-center"
          style="width: 2.3rem; height: 2.3rem; margin-left: auto; margin-right: auto; margin-bottom: 1em;"
          role="status"
        />
      </b-col>
      <b-col
        cols="6"
        class="mobileScrn"
      >
        <user-settings
          :settings="settings"
          :show-user-setting="showUserSetting"
        />
      </b-col>
      <b-col
        cols="6"
        class="mobileScrn"
      >
        <install-script
          :settings="settings"
          :billing="billing"
          :user="user"
          :script="script"
        />
      </b-col>
      <b-col
        cols="6"
        class="mobileScrn"
      >
        <user-tickets
          v-if="tickets"
          :tickets="tickets"
        />
        <div
          v-else
          class="d-flex spinner-border text-primary justify-content-center"
          style="width: 2.3rem; height: 2.3rem; margin-left: auto; margin-right: auto; margin-bottom: 1em;"
          role="status"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <user-activity
          v-if="activity"
          :activity="activity"
          class="user-activity-min-width"
        />
        <div
          v-else
          class="d-flex spinner-border text-primary justify-content-center"
          style="width: 2.3rem; height: 2.3rem; margin-left: auto; margin-right: auto; margin-bottom: 1em;"
          role="status"
        />
      </b-col>
      <b-col
        cols="12"
      >
        <user-billing
          v-if="user && invoices"
          :invoices="invoices"
          :payments="payments"
          :ci="ci"
          :crs="crs"
          :user="user"
          @removeCreditNote="removeCreditNote"
        />
        <div
          v-else
          class="d-flex spinner-border text-primary justify-content-center"
          style="width: 2.3rem; height: 2.3rem; margin-left: auto; margin-right: auto; margin-bottom: 1em;"
          role="status"
        />
      </b-col>
    </b-row>
  </span>

</template>

<script>
import {
  BRow, BCol, BSpinner, BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserIdentification from './user-details/UserIdentification.vue'
import Package from './user-details/Package.vue'
import UserSettings from './user-details/UserSettings.vue'
import Activity from './user-details/Activity.vue'
import Tickets from './user-details/Tickets.vue'
import Billing from './user-details/Billing.vue'
import InstallScript from './user-details/InstallScript.vue'
import Action from './user-details/Action.vue'

export default {
  components: {
    BRow,
    BCol,
    action: Action,
    'user-identification': UserIdentification,
    'user-package': Package,
    'user-settings': UserSettings,
    'user-activity': Activity,
    'user-tickets': Tickets,
    'user-billing': Billing,
    'install-script': InstallScript,
    BSpinner,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      user: null,
      billing: null,
      settings: null,
      tickets: null,
      activity: null,
      invoices: null,
      payments: null,
      ci: null,
      crs: null,
      script: null,
      action: null,
      avatar: null,
      showUserSetting: false,
      showDetail: false,
      discountHistory: null,
      options: [
        { value: null, text: 'SELECT PACKAGE' },
        // { value: '7003', text: 'STARTER PACKAGE - €199' },
        { value: '7004', text: 'PROFESSIONAL PACKAGE - €399' },
        { value: '7005', text: 'AGENCY PACKAGE - €599' },
        { value: '7006', text: 'ENTERPRISE PACKAGE - €999' },
        { value: '5003', text: 'CASINO AGENCY PACKAGE - €599' },
        { value: '6003', text: 'CASINO ENTERPRISE PACKAGE - €999' },
      ],
      selectedOption: null,
    }
  },
  created() {
    this.fetchUserSplitDetails() // beta speed testing
    this.fetchUserDetails()
    // after 2 secibds console log this.user
  },
  mounted() {
    this.showDetail = false
    const refreshId = setInterval(() => {
      if (this.user) {
        this.showDetail = true
        clearInterval(refreshId)
      }
    }, 250)
  },
  methods: {
    changePackage() {
      // if this.selectedOption is null, then return
      if (!this.selectedOption) {
        this.notify({
          variant: 'danger',
          text: 'Please select a package',
        })
        return
      }
      // Ask for confirmation
      this.confirmationAlert('Are you sure you want to change the package for this user?')
        .then(async res => {
          if (res.isConfirmed) {
            const response = await axios.post('api/users/change_package', {
              // eslint-disable-next-line radix
              uid: parseInt(this.$route.params.id),
              // get the package name from the options array of the selected value
              package: this.selectedOption,
            })
            // Success response
            this.fetchUserSplitDetails() // beta speed testing
            this.fetchUserDetails()
            if (response.status === 200) {
              this.notify({
                variant: 'success',
                text: 'Package changed successfully',
              })
              this.fetchUserDetails()
              this.fetchUserSplitDetails() // beta speed testing
            } else {
              this.notify({
                variant: 'danger',
                text: 'Something went wrong',
              })
            }
          } else {
            this.notify({
              variant: 'danger',
              text: 'Package change cancelled',
            })
          }
        })
    },
    setPackage(e) {
      this.selectedOption = e.target.value
    },

    // beta speed testing
    fetchUserSplitDetails() {
      // eslint-disable-next-line radix
      this.$store.dispatch('users/fetchUserSplitDetails', parseInt(this.$route.params.id))
        .then(res => {
          this.invoices = res.invoices
          this.activity = res.activity
          this.tickets = res.tickets
          this.action = res.action
        })
        .catch(error => { console.log(error) })
    },

    fetchUserDetails() {
      // eslint-disable-next-line radix
      this.$store.dispatch('users/fetchUserDetails', parseInt(this.$route.params.id))
        .then(res => {
          this.user = res.user
          this.settings = res.settings
          this.billing = res.billing
          this.payments = res.payments
          this.ci = res.customInterventions
          this.crs = this.mergeCRIOptionalFeatures(res.optionalFeatures, res.customRecurringServices)
          this.script = res.script
          this.avatar = res.avatar

          if (this.user.administrator === 1) this.user.company = 'TrafficManager'

          if (res.settings.setstep1 === 0) this.showUserSetting = false
          else if (res.user.active === 1) this.showUserSetting = true
          this.fetchDiscountHistory(this.user.uid)
        })
        .catch(error => { console.log(error) })
    },
    fetchDiscountHistory(uid) {
      // eslint-disable-next-line radix
      this.$store.dispatch('billing/fetchDiscountHistory', uid)
        .then(res => {
          this.discountHistory = res
        })
    },
    updateUser(user) {
      this.user = user
    },
    refreshInvoice() {
      this.fetchUserDetails()
      this.fetchUserSplitDetails()
    },
    removeCreditNote(creditNote) {
      this.invoices = this.invoices.filter(obj => obj.credit_note !== creditNote)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'Pro forma invoice was removed',
          variant: 'success',
        },
      })
    },
    updateUserInvoices(userInvoices) {
      this.invoices = userInvoices
    },
    mergeCRIOptionalFeatures(OptFeatures, csr) {
      const recurringCSR = []
      for (const item of csr) { // eslint-disable-line
        const csrObj = {
          id: item.id,
          name: item.name,
          price: item.price,
          type: 'csr',
          created_at: item.created_at,
        }
        recurringCSR.push(csrObj)
      }
      for (const item of OptFeatures) { // eslint-disable-line
        const optionFeature = {
          id: item.id,
          name: item.invoice_row_text,
          price: item.price,
          type: 'feature',
          created_at: item.created_at,
        }
        recurringCSR.push(optionFeature)
      }
      return recurringCSR
    },
  },
}
</script>

<style lang="scss">
.user-details-wrapper{
  .action-btn-group {
    max-width: 90rem;
    margin: auto;

    button {
      min-width: 244px;
      margin: 0 5px;
    }
  }

  .justify-evenly {
    display: flex;
    justify-content: space-evenly;
    margin: 8px 0;
  }

  .optionFix {
    background-color:white;
    color:gray;
    text-align: center;
    font-weight: bolder;
    gap:4px;
    padding:2px;
    margin:5px;
  }
  .optionFix2 {
    background-color: white;
    color:#d50d6a;
    text-align: center;
    font-weight: bolder;
    gap:4px;
    padding:2px;
    margin:5px;
  }

  .cmtx-5 {
    margin-top: 5px;
  }

  .clh-26 {
    line-height: 26px;
  }

  .cfs-16 {
    font-size: 16px;
  }

  .user-custom-card .card-body {
    border-top: 1px solid #e0e0e0;
    padding-top: 23px !important;
  }

  .user-settings-card .card-text {
    margin: 17px 0;
  }
}

.invoices-wrapper {
  border: 1px solid #c7c7c7;
  border-left: 4px solid #e91e63;
  padding: 15px 26px;
  .invoice-item {
    .item-close {
      background: #e91e63;
      border-left: 1px solid #c7c7c7;
      border-bottom: 1px solid #c7c7c7;
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
      transition: .5s;

      &:hover {
        background: #b30d46;
      }
    }

    .item-pending {
      background: #F1C40F !important;
      cursor: unset;
    }

    .item-paid {
      background: #659be0 !important;
      cursor: unset;
    }

    .item-overdue{
      background: #e91e63 !important;
      cursor: unset;
    }
  }
}
.user-activity-min-width{
  min-width:330px;
}

@media only screen and (max-width: 450px)  {
  .mobileScrn{
    min-width:440px !important;
  }
  .user-activity-min-width{
    min-width: 408px;
  }

  .action-btn-group button {
    min-width: 90% !important;
  }
  .mobileScrn .btn{
    min-width: 90% !important;
  }
  .mobileScrn .mobileScrnInput{
    width: 90% !important;
  }

}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .mobileScrn{
    min-width:420px !important;
  }
  .user-activity-min-width{
    min-width: 388px;
  }

  .action-btn-group button {
    min-width: 90% !important;
  }
  .mobileScrn .btn{
    min-width: 90% !important;
  }
  .mobileScrn .mobileScrnInput{
    width: 89% !important;
  }

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .mobileScrn{
    min-width:410px !important;
  }
  .user-activity-min-width{
    min-width: 378px;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {
  .mobileScrn{
    min-width:390px !important;
  }
  .user-activity-min-width{
    min-width: 358px;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {
  .mobileScrn{
    min-width:366px !important;
  }
  .user-activity-min-width{
    min-width: 335px;
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .mobileScrn{
    min-width:350px !important;
  }
  .user-activity-min-width{
    min-width: 318px;
  }
}

</style>
